'use client'

import { useEventContext } from '@/components/providers/eventContext'
import { redirectToUrl } from '@/util/browser'
import { useRouter } from 'next/navigation'
import type { AnchorHTMLAttributes } from 'react'

type LinkPropsType = AnchorHTMLAttributes<HTMLAnchorElement> & {
  legacyBehavior?: boolean
  prefetch?: boolean
  scroll?: boolean
}

const Link = ({ legacyBehavior, href, ...props }: LinkPropsType) => {
  const publicProps = { ...props, scroll: undefined, prefetch: undefined }
  const router = useRouter()
  const { getEventProps } = useEventContext()
  const gaEvent = getEventProps()

  const publicHref = /^(https?:|\/\/|\?)/.test(href || '') ? href : `${process.env.NEXT_PUBLIC_BASE_PATH}${href}`
  const onClick = () => {
    if (!href) return
    const isExternal = /^(https?:|\/\/)/.test(href)

    if (isExternal) {
      redirectToUrl(publicHref || '')
    } else {
      router.replace(href, { scroll: props.scroll })
    }
  }

  if (legacyBehavior) {
    return <span role="button" tabIndex={0} onClick={onClick} {...publicProps} />
  }
  return <a href={publicHref} {...publicProps} {...(gaEvent['data-evna'] ? { ...gaEvent } : {})} />
}
export default Link
